module.exports = {
  appVersion: '1.0.5.6',
  SPORTSRADAR_CLIENT_ID: 'STM-AAAAKO',
  CONTACT_NO: '(+44)788-492-6735',
  WHATSAPP_NO: '+447884926735',
  NEW_WHATSAPP_NO: '447938442927',
  genderItems: ['Male', 'Female', 'I prefer not to say'],
  // loaderImg: 'https://fairplay-image.fairplay.club/loader.gif',
  landingPagePromotionVideo: '/landing-promotion.mp4',
  paymentUrl: 'https://uatpayments.travelish.life/v2/renderpaymentgateway',
  AstroPayUrl: 'https://uatpayments.travelish.life/v2/renderastropay',
  // paymentUrlBangla:
  //   'https://admin.paymentgateway-bd.fairplay.club/v2/renderpaymentgateway',
  paymentUrlBangla:
    'https://bd.uatpaymentgateway.fairplay.club/v2/renderpaymentgateway',
  AstroPayUrlBangla:
    'https://admin.paymentgateway-bd.fairplay.club/v2/renderastropay',
  s2sBtagCode: 'a_20b_12c_',
  sharechatBtagCode: 'a_9b_1c_Schat',
  exoclickBtagCode: 'a_9b_1c_exo',
  trellBtagCode: 'a_62b_24c_',
  onatrack: 'dgmfpfan',
  trackierOragnicId: '6223d06e22878d00112d105e',
  shareModalDateRange: 10,
  shareStatusModalVideo:
    'https://www.youtube.com/embed/wFCTryrrWBQ?modestbranding=1&showinfo=0&rel=0&autoplay=1&loop=1&fs=1',
  fairplayAPK:
    'https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/FAIRPLAY1.0.1.apk',
  UATfairplayAPK:
    'https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/FAIRPLAY1.0.1.apk',
  BDfairplayAPK:
    'https://s3.ap-south-1.amazonaws.com/fairplay-bd.apk/fairplay-bd.apk',
  BDUATfairplayAPK:
    'https://s3.ap-south-1.amazonaws.com/fairplay-bd.apk/fairplay-bd.apk',
  resendcodeSec: 30,
  referralsPercentage: '15%',
  removeSportsRadar: false,
  digiMazeOutbrain: 'DigiMaze_2',
  onTrackQuickRegisterBtag: 'DigiMaze',
  normalOutbrainPixelCode: 'OutBrain',
  s3Url: 'https://nn1eku1xgk.execute-api.ap-south-1.amazonaws.com/uploadToS3',
  callextensions: ['9999997'],
  limitForTopBet: 25,
  fromDateForTopBet: 7,
  verifySms: true,
  verifyStandardOTP: '123456',
  langDropDown: true,
  captchaOn: true,
  joshDailyHuntBtag: 'a_9b_1c_joshasiacup',
  balanceCheck: 1000000,
  whatsappLink: 'https://wa.link/fpapi',
  indexLink: 'https://fairplay.co.in',
  bonusExpireDate: 21
}
